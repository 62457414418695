export const GuideCheckoutStatus = {
  NEW: 'new',
  ORDERED: 'ordered',
  FINALIZE: 'finalize'
};

export const GuideCheckoutPlaceOrderButton = {
  SHIPNOW: 'Ship Now',
  PLACEORDER: 'Place Order',
};

export const GuideStatusButton = {
  NEW: 'Buy Now',
  ORDERED: 'Buy Again',
  FINALIZED: 'Finalize'
};

export const productsOptions = [
  {
    id: 1,
    label: '25 copies',
    qty: 25,
    price: '115',
  },
  {
    id: 2,
    label: '50 copies',
    price: '210',
    qty: 50,
  },
  {
    id: 3,
    label: '100 copies',
    price: '390',
    qty: 100,
  },
  {
    id: 4,
    label: '150 copies',
    price: '545',
    qty: 150,
  },
  {
    id: 5,
    label: '200 copies',
    price: '675',
    qty: 200,
  },
  {
    id: 6,
    label: '250 copies',
    price: '785',
    qty: 250,
  },
];